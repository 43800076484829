
interface VapiType {
  brand: string;
  typeClass: string;
  bM6: string;
  materialNo: string;
  modelName: string;
  make: string;
  modelYear: string;
  powerTrain: string;
  nstGroup: string;
  nstGroupName: string;
  marketLaunchDate: string;
  launchStatus: string;
  msrpChinaStandardWithVat: string;
  id: string;

}
interface BrandsArrayType {
  key: string;
  value: string;
}

import { ref, onMounted, reactive, toRefs, defineComponent } from "vue";
import { ColumnProps } from "ant-design-vue/es/table/interface";
import {
  getBrandsArrayListInfo,
  getMakeArrayListInfo,
  getPowerTrainArrayListInfo,
  getVapiList,
  getStatusArrayList,
  getFilterListSearch,
} from "@/API/systemVehicle/systemVehicleIndex";
import SystemVehicleImportContent from "@/views/System/VehicleModelMaster/components/systemVehicleImportContent.vue";
import blobDownload from '@/utils/blobDownload'
import useTableHeight from "@/hooks/useTableHeight";
import Pagination from "@/components/Pagination.vue";

const columns = [
      {
        title: "Brand",
        dataIndex: "brand",
        key: "brand",
        width: 100,
      },
      {
        title: "Type Class",
        dataIndex: "typeClass",
        key: "typeClass",
        width: 100,
      },
      {
        title: "BM6",
        dataIndex: "bM6",
        key: "bM6",
        width: 100,
      },
      {
        title: "Material No",
        dataIndex: "materialNo",
        key: "materialNo",
        width: 160,
        slots: { customRender: "ellipsis" },
      },
      {
        title: "Model Name",
        dataIndex: "modelName",
        key: "modelName",
        width: 280,
        slots: { customRender: "ellipsis" },
      },
      {
        title: "Make",
        dataIndex: "make",
        key: "make",
        width: 80,
      },
      {
        title: "Model Year",
        dataIndex: "modelYear",
        key: "modelYear",
        width: 100,
      },
      {
        title: "Power Train",
        dataIndex: "powerTrain",
        key: "powerTrain",
        width: 100,
      },
      {
        title: "Nst Group",
        dataIndex: "nstGroup",
        key: "nstGroup",
        width: 140,
      },
      {
        title: "Nst Group Name",
        dataIndex: "nstGroupName",
        key: "nstGroupName",
        width: 240,
        slots: { customRender: "ellipsis" },
      },
      {
        title: "Market Launch Date",
        dataIndex: "marketLaunchDate",
        key: "marketLaunchDate",
        width: 160,
      },
      
      {
        title: "Launch Status",
        dataIndex: "launchStatus",
        key: "launchStatus",
        width: 150,
      },
      {
        title: "MSRP China Standard w/ VAT",
        dataIndex: "msrpChinaStandardWithVat",
        key: "msrpChinaStandardWithVat",
        slots: { customRender: 'msrpChinaStandardWithVat' },
        width: 260,
      },
      {
        title: "Operation",
        dataIndex: "operation",
        slots: { customRender: "operation" },
        width: 120,
      },
    ];
export default defineComponent({
  components: { 
    SystemVehicleImportContent,
    Pagination,
  },
  setup() {
    const { tableHeight } = useTableHeight();
    //查询框数据
    const searchData = reactive<any>({
      brandId: null,
      make: null,
      nstModelName: null,
      powerTrain: null,
      status: null,
    });

    //brandsArray的数据，从后端获取
    const brandsArray = ref<BrandsArrayType[]>([]);
       //获取brandsArray
    const getBrandsArray = () => {
      getBrandsArrayListInfo().then((res) => {
        for (let i = 0; i < res.length; i++) {
            brandsArray.value.push({
              key: res[i].id,
              value: res[i].brandNameEn,
            });
          }
      });
    };
    //makeArray的数据，从后端获取
    const makeArray = ref<BrandsArrayType[]>([]);
       //获取markArray
    const getMakeArray = () => {
      getMakeArrayListInfo().then((res) => {
      //   for (let i = 0; i < res.length; i++) {
      //       makeArray.value.push({
      //         key: res[i],
      //         value: res[i],
      //       });
      //     }
      makeArray.value = res
      });
      
    };
    //powerTrainArray
    const powerTrainArray = ref<BrandsArrayType[]>([]);
       //获取powerTrainArray
    const getPowerTrainArray = () => {
      getPowerTrainArrayListInfo().then((res) => {
        powerTrainArray.value = res
      });
    };
    //statusArray
    const statusArray = ref<BrandsArrayType[]>([]);
       //获取statusArray
    const getStatusArray = () => {
      getStatusArrayList().then((res) => {
        for (let i = 0; i < res.length; i++) {
            statusArray.value.push({
              key: res[i].id,
              value: res[i].name,
            });
          }
      });
    };

    //分页
    const pagination = reactive({
      total: 0,
      currentPage: 1,
      pageSize: 10,
    });

    //表格

    const dataSource = ref<VapiType[]>([]);
   
    //表格高度
    // const tableHeight = window.innerHeight - 430;
    // const tableWidth = window.innerWidth
    //表格类型
    type Key = ColumnProps["key"];
    //抽屉事件
    const visible = ref(false);
    //导出表格
    const exportTable = () => {
      const { brandId, make, nstModelName, powerTrain, status } = searchData
      blobDownload({
        url: `/vapi/vehicleModelInfo/exportExcel`,
        method: "get",
        params: {
           brandId,
           make,
           nstModelName,
           powerTrain,
           status
        }
      });
    };
    
    //获取表格status
    const searchParams = reactive<any>({
      brandId: null,
      makeId: null,
      nstModelName: null,
      powerTrainId: null,
      status: null,
    });

    //获取表格数据
    const getTableData = () => {
      const params = {
        page: pagination.currentPage,
        size: pagination.pageSize,
        ...searchParams,
        sort: 'materialId,asc'
      };

      getVapiList(params).then((res) => {
        pagination.total = res.totalElements;
        dataSource.value = [];
        for (let i = 0; i < res.content.length; i++) {
          const one = res.content[i];
          dataSource.value.push({
            brand: one.brand,
            typeClass: one.typeClassEn,
            bM6: one.bm6,
            materialNo: one.materialId,
            modelName: one.nstModelNameEn,
            make: one.make,
            modelYear: one.modelYear,
            powerTrain: one.powerTrain,
            nstGroup: one.nstGroup,
            nstGroupName: one.nstGroupName,
            marketLaunchDate: one.marketLaunchDate,
            launchStatus: one.statusName,
            msrpChinaStandardWithVat: one.msrpChinaStandardWithVat,
            id: one.id,
     
          });
        }
      });
    };
    const searchTable = () => {
      searchParams.brandId = searchData.brandId;
      searchParams.powerTrainId = searchData.powerTrain;
      searchParams.makeId = searchData.make;
      searchParams.nstModelName = searchData.nstModelName;
      searchParams.status = searchData.status;
      pagination.currentPage = 1
      getTableData();
    };
    //提交checkingPlan

    //更改分页页签
    const pageChange = (page: number) => {
      pagination.currentPage = page;

      getTableData();
    };
    //改变每页大小
    const sizeChange = (page: number, pageSize: number) => {
      pagination.pageSize = pageSize;
      pagination.currentPage = 1;
      getTableData();
    };

    //重新设置筛选信息
    //导入
    const importTable = (): void => {
      visible.value = !visible.value;
    };
    const hanlderSearchClick = (): void => {
      // alert("导入成功");
      pagination.currentPage = 1;
      pagination.pageSize = 10;
      getTableData()
    };

    // 
    const changeBrand = () => {
      searchData.make = ''
      searchData.powerTrain = ''
    }

    // 联动
    const handlerFocus = (target: string) => {
        const params = {
          brand: searchData.brandId,
          make: target === 'make' ? '' : searchData.make,
          powerTrain: target === 'make' ? searchData.powerTrain : '',
          target
        }
        makeArray.value = []
        powerTrainArray.value = []
        getFilterListSearch(params).then(res => {
          if (target === 'make') {
            makeArray.value = res
          } else {
            powerTrainArray.value = res
          }
        })
    }
 

    onMounted(() => {
      getTableData();
      getBrandsArray();
      getMakeArray();
      getPowerTrainArray();
      getStatusArray();
    });

    return {
      searchTable,
      visible,
      importTable,
      sizeChange,
      pageChange,
      exportTable,
      hanlderSearchClick,
      tableHeight,
      dataSource,
      columns,
      ...toRefs(pagination),
      ...toRefs(searchData),
      brandsArray,
      makeArray,
      powerTrainArray,
      statusArray,
      getTableData,
      handlerFocus,
      changeBrand,
    };
  },
});
